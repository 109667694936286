import logo from "assets/img/header/logo.svg";
import whitePaperPdf from "assets/img/header/White paper.pdf";
import "./index.scss";

const Header = () => {
  const handleX = () => {
    window.open("https://twitter.com/fugutoxic01", "_blank");
  };
  const handletel = () => {
    window.open("https://t.me/FuguToxicBot", "_blank");
  };
  const handleOpenApp = () => {
    window.open("https://t.me/FuguToxicBot", "_blank");
  };
  const handleVerus = () => {
    window.open("https://www.verus.website", "_blank");
  }
  return (
    <div className="header_box">
      <div>
        <img src={logo} alt=""></img>
      </div>
      <div className="header_btn">
        <div onClick={handleOpenApp}>
          <p>FuguAPP</p>
        </div>
        <a href={whitePaperPdf} target="_blank" rel="noopener noreferrer">
          <p>White paper</p>
        </a>
        <div onClick={handleX}>
          <p> X</p>
        </div>
        <p onClick={handletel}>Telegram</p>
        <p onClick={handleVerus}>Verus</p>
        {/* <p>Discord</p> */}
      </div>
    </div>
  );
};
export default Header;
